import { LinkItem, MenuGroup, Section } from '@atlaskit/menu'
import { Modal, ModalTransition } from '@atlaskit/onboarding'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'
import { Box, Flex } from '@rebass/grid'
import { Alert, Caption, Card, Spinner, Title } from '@tkxs/cast-ui'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { withBaseIcon } from 'react-icons-kit'
import { ic_card_travel } from 'react-icons-kit/md/ic_card_travel'
import styled from 'styled-components'
import useSWR from 'swr'
import { CTitle, LightCTitle, NumeroCTitle } from '../common/cast-ui-fixes'
import { ControlNavbar, SessionUser, SLink } from '../common/common'
import { isAdmin } from '../utils/auth'
import { camelizeKeys } from '../utils/converters'
import Utilization from './utilization'

import _ from 'lodash'

import { useCookies } from '../common/helpers'

const StyledPage = styled.div`
  .page {
  }
`

const IconContainer = withBaseIcon({
  size: 24,
  style: {
    color: '#aa1e2b',
    marginRight: '0.5rem',
    top: '-1px',
    position: 'relative',
  },
})

const SLinkItem = styled(LinkItem)`
  margin-top: 10px !important;
`

const SSection = styled(Section)`
  max-height: 247px;
  overflow: hidden;
  &:hover,
  &:focus {
    overflow: auto;
  }
`

interface Props {
  pageProps: any
}

export function Index({ pageProps }: Props) {
  const router = useRouter()
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {},
  })
  const [isActive, setIsActive] = useCookies('nextStepAlert', true)

  const user: SessionUser = camelizeKeys(session?.user)
  let profile: any

  if (!session) {
    router.push(`/login?returnTo=${router.asPath}`)
  }

  const sessionUser: SessionUser = session && session?.user

  const { data: dashboard, error: dashboardError } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}dashboard?employee_id=${sessionUser.profile.employeeId}&user_id=${sessionUser.id}`,
  )

  return (
    session &&
    session.user && (
      <StyledPage>
        <ControlNavbar>
          <Flex flexDirection="column">
            {profile && <CTitle size={20}>Dashboard</CTitle>}
            <LightCTitle size={10}>Welcome {user.name}</LightCTitle>
          </Flex>
        </ControlNavbar>
        {dashboardError ? (
          <Alert alertStyle="danger" lightMode>
            <b>
              HTTP Status {dashboardError.response.status} (
              {dashboardError.response.statusText}):
            </b>{' '}
            {dashboardError.response.data.message}
          </Alert>
        ) : (
          <>
            <Flex>
              <Flex width={[1, 1 / 3]} flexWrap={'wrap'}>
                <Box mr={3} width={[1]} mb={3}>
                  <Card highlightBorder="top" bgColor="#FFFFFF">
                    <Title>
                      <IconContainer icon={ic_card_travel} />
                      Tasks Status
                    </Title>
                    <div>
                      <b>
                        {isAdmin(session)
                          ? 'Add or manage system tasks'
                          : 'Add or manage your tasks'}
                      </b>
                      <br />
                      <br />
                      <hr />
                      <br />
                      {!dashboard ? (
                        <Spinner
                          backgroundColor=""
                          borderColor="#aa1e2b"
                          size={20}
                        />
                      ) : (
                        <Flex justifyContent="space-between">
                          <Box>
                            <Caption size={20}>In Progress</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.tasksStats.inprogressCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>To Do</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.tasksStats.todoCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Completed</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.tasksStats.completedCount}
                            </NumeroCTitle>
                          </Box>
                        </Flex>
                      )}
                    </div>
                  </Card>
                </Box>
                <Box mr={3} width={[1]}>
                  <Card highlightBorder="top" bgColor="#FFFFFF">
                    <Title>
                      <IconContainer icon={ic_card_travel} />
                      Tasks Priority
                    </Title>
                    <div>
                      <b>
                        {isAdmin(session)
                          ? 'View task priority levels across the system'
                          : 'View task priority levels'}
                      </b>
                      <br />
                      <br />
                      <hr />
                      <br />
                      {!dashboard ? (
                        <Spinner
                          backgroundColor=""
                          borderColor="#aa1e2b"
                          size={20}
                        />
                      ) : (
                        <Flex justifyContent="space-between">
                          <Box>
                            <Caption size={20}>High</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.tasksStats.highCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Normal</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.tasksStats.midCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Low</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.tasksStats.low}
                            </NumeroCTitle>
                          </Box>
                        </Flex>
                      )}
                    </div>
                  </Card>
                </Box>
              </Flex>
              <Flex width={[1, 1 / 3]} flexWrap={'wrap'}>
                <Box width={[1]} mb={3} mr={3}>
                  <Card highlightBorder="top" bgColor="#FFFFFF">
                    <Title>
                      <IconContainer icon={ic_card_travel} />
                      Projects
                    </Title>
                    <div>
                      <b>
                        {isAdmin(session)
                          ? 'Add or manage system projects'
                          : 'View team projects, add or manage private projects'}
                      </b>
                      <br />
                      <br />
                      <hr />
                      <br />
                      {!dashboard ? (
                        <Spinner
                          backgroundColor=""
                          borderColor="#aa1e2b"
                          size={20}
                        />
                      ) : (
                        <Flex justifyContent="space-between">
                          <Box>
                            <Caption size={20}>Private</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.projectStats.privateCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Organization</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.projectStats.globalCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Department</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.projectStats.departmentCount}
                            </NumeroCTitle>
                          </Box>
                        </Flex>
                      )}
                    </div>
                  </Card>
                </Box>
                <Box width={[1]} mr={3}>
                  <Card highlightBorder="top" bgColor="#FFFFFF">
                    <Title>
                      <IconContainer icon={ic_card_travel} />
                      Timesheets
                    </Title>
                    <div>
                      <b>
                        {isAdmin(session)
                          ? 'Add or manage system timesheets'
                          : 'Add or manage your tasks'}
                      </b>
                      <br />
                      <br />
                      <hr />
                      <br />
                      {!dashboard ? (
                        <Spinner
                          backgroundColor=""
                          borderColor="#aa1e2b"
                          size={20}
                        />
                      ) : (
                        <Flex justifyContent="space-between">
                          <Box>
                            <Caption size={20}>Forecasts</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.timesheetStats.forecastCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Reports</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.timesheetStats.reportCount}
                            </NumeroCTitle>
                          </Box>
                          <Box>
                            <Caption size={20}>Unapproved</Caption>
                            <NumeroCTitle size={20}>
                              {dashboard.timesheetStats.unapprovedCount}
                            </NumeroCTitle>
                          </Box>
                        </Flex>
                      )}
                    </div>
                  </Card>
                </Box>
              </Flex>
              <Flex width={[1, 2 / 3]}>
                <Box mr={3} width={[1]} mb={3}>
                  <Card highlightBorder="top" bgColor="#FFFFFF">
                    <Title>
                      <IconContainer icon={ic_card_travel} />
                      Next Steps
                    </Title>
                    <div>
                      <b>
                        {isAdmin(session)
                          ? 'Add or manage system projects'
                          : 'View team projects, add or manage private projects'}
                      </b>
                      <br />
                      <br />
                      <hr />
                      {!dashboard ? (
                        <Spinner
                          backgroundColor=""
                          borderColor="#aa1e2b"
                          size={20}
                        />
                      ) : (
                        <MenuGroup>
                          <SSection>
                            {dashboard?.nextSteps?.steps?.map((step) => (
                              <SLinkItem
                                key={_.snakeCase(step)}
                                description=""
                                isSelected={true}
                              >
                                {step}
                              </SLinkItem>
                            ))}
                          </SSection>
                        </MenuGroup>
                      )}
                    </div>
                  </Card>
                </Box>
                <Box mr={3} width={[1]}>
                  <Card highlightBorder="top" bgColor="#FFFFFF">
                    <Title>
                      <IconContainer icon={ic_card_travel} />
                      Risks
                    </Title>
                    <div>
                      <b>
                        {isAdmin(session)
                          ? 'Add or manage system timesheets'
                          : 'Add or manage your tasks'}
                      </b>
                      <br />
                      <br />
                      <hr />
                      {!dashboard ? (
                        <Spinner
                          backgroundColor=""
                          borderColor="#aa1e2b"
                          size={20}
                        />
                      ) : (
                        <MenuGroup>
                          <SSection>
                            {dashboard?.risks?.map((risk) => (
                              <SLinkItem
                                key={_.snakeCase(risk?.id)}
                                description={risk?.project?.name}
                                isSelected={true}
                              >
                                {risk?.name}
                              </SLinkItem>
                            ))}
                          </SSection>
                        </MenuGroup>
                      )}
                    </div>
                  </Card>
                </Box>
              </Flex>
            </Flex>
          </>
        )}
        <Flex width={[1]} mt={3}>
          <Card highlightBorder="top" bgColor="#FFFFFF">
            <Title>
              <IconContainer icon={ic_card_travel} />
              Resource Utilization{' '}
              <small>
                <Link href={`/utilization`} passHref>
                  <SLink>View Full</SLink>
                </Link>
              </small>
            </Title>
            <div>
              <Utilization pageProps={{ ...pageProps, embed: true }} />
            </div>
          </Card>
        </Flex>
        <ModalTransition>
          {isActive && dashboard?.nextSteps?.steps && (
            <Modal
              actions={[
                {
                  onClick: () => setIsActive(false),
                  text: 'Continue',
                },
                {
                  onClick: () =>
                    router.push(
                      `/timesheets/${dashboard?.nextSteps?.timesheet}`,
                    ),
                  text: 'Review Timesheet',
                },
              ]}
              heading={`Welcome Back, ${sessionUser.name}`}
              key="welcome"
            >
              <p>Here is a recap from your last report</p>
              <Tabs id="default">
                <TabList>
                  <Tab>Next Steps</Tab>
                  <Tab>Risks</Tab>
                </TabList>
                <TabPanel>
                  <MenuGroup>
                    <Section>
                      {dashboard?.nextSteps?.steps?.map((step) => (
                        <SLinkItem
                          key={_.snakeCase(step)}
                          description=""
                          isSelected={true}
                        >
                          {step}
                        </SLinkItem>
                      ))}
                    </Section>
                  </MenuGroup>
                </TabPanel>
                <TabPanel>
                  {dashboard?.risks?.map((risk) => (
                    <SLinkItem
                      key={_.snakeCase(risk?.id)}
                      description={risk?.project?.name}
                      isSelected={true}
                    >
                      {risk?.name}
                    </SLinkItem>
                  ))}
                </TabPanel>
              </Tabs>
            </Modal>
          )}
        </ModalTransition>
      </StyledPage>
    )
  )
}
export default Index
